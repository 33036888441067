<template>
  <div class="home-container">
    <el-tabs
      v-model="activeTab"
      @tab-click="handleTabClick(activeTab)"
      class="middle"
    >
      <el-tab-pane
        v-for="item in tabs"
        :key="item.label"
        :label="item.label"
        :name="item.name"
      >
        <div class="article-list">
          <div v-if="articleList.length">
            <template v-for="(item, index) in articleList">
              <baseCard
                v-bind="item"
                @refreshData="queryData"
                :key="index"
                :showOpeBtn="false"
                :showStatusTag="true"
              />
            </template>
          </div>
          <div v-else class="no-result">No result</div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div class="customer-info">
      <customerInfo />
    </div>
  </div>
</template>

<script>
import baseCard from "@/components/baseCard.vue";
import customerInfo from "@/components/customerInfo.vue";
import { getUserArticles } from "../api/index";
import { getUserId } from "@/utils/store";
export default {
  name: "home",

  components: {
    baseCard,
    customerInfo,
  },

  data() {
    return {
      articleList: [],
      activeTab: "All",
      tabs: [
        {
          label: "All",
          name: "All",
        },
        {
          label: "Published",
          name: "Published",
        },
        {
          label: "Under Review",
          name: "Under Review",
        },
        {
          label: "Rejected",
          name: "Rejected",
        },
      ],
    };
  },

  mounted() {
    this.queryData();
  },

  methods: {
    queryData(status = "") {
      const user_id = getUserId();
      getUserArticles(user_id, status).then((res) => {
        const { data, code } = res.data;
        if (code === 0) {
          this.articleList = data
            .map(function (item) {
              const { member_status, article_id, article, student_id } = item;
              const {
                _id,
                title,
                content,
                create_time,
                icon,
                tags,
                cover_address,
                content_status,
                type,
              } = article;
              return {
                member_status,
                student_id,
                article_id,
                _id,
                title,
                content,
                create_time,
                icon,
                tags,
                cover_address,
                content_status,
                type,
              };
            })
            .filter((i) => i.content_status !== "draft");
        }
      });
    },

    handleTabClick(tab) {
      this.articleList = [];
      const _status =
        tab === "Under Review"
          ? "review"
          : tab === "Rejected"
          ? "refuesd"
          : "pass";
      this.queryData(tab === "All" ? "" : _status);
    },
  },
};
</script>

<style lang="scss" scoped>
.home-container {
  height: 100%;
  display: flex;

  .article-list {
    flex: 1;
    margin-right: 20px;
  }
  .middle {
    flex: 1;
    margin-right: 20px;
  }
  .no-result {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
  }
}
</style>
